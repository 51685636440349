import AuthService from './AuthService';

class BaseService{
    constructor() {
        this.apiBaseUrl=process.env.REACT_APP_API_URL;
    }

    async getBases(clientId){
        const token = AuthService.getAccessToken();
        const loginAsUri = clientId ? `?loginAs=${clientId}` : '';
        const url = `${this.apiBaseUrl}/bases` + loginAsUri;
        // console.log(`fetching ${url}`)
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // console.log(data);

        return data;
    }

    async deleteBase(clientId, baseId){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/bases/${clientId}/${baseId}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            throw new Error(`Failed to delete base: ${clientId}`);
        }
    }

    async addBase(baseData){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/bases`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(baseData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); // Define `data` here with the JSON response
        return data;
    }
}
const baseService = new BaseService();
export default baseService;    