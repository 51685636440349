import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import baseService from '../services/BaseService'; // Adjust the import based on your file structure

const BaseList = ({ clientId, onCancel }) => {
    const [bases, setBases] = useState([]);
    const [newBaseId, setNewBaseName] = useState('');

    useEffect(() => {
        fetchBases();
    }, []);

    const fetchBases = async () => {
        try {
            console.log('clientId:', clientId);
            const data = await baseService.getBases(clientId);
            setBases(data);
        } catch (error) {
            console.error('Error fetching bases:', error);
        }
    };

    const handleAddBase = async () => {
        if (newBaseId.trim() === '') return;
        try {
            const newBase = { "baseId": newBaseId, "clientId": clientId };
            await baseService.addBase(newBase);
            setNewBaseName('');
            fetchBases();
        } catch (error) {
            console.error('Error adding base:', error);
        }
    };

    const handleDeleteBase = async (baseId) => {
        try {
            await baseService.deleteBase(clientId, baseId);
            fetchBases();
        } catch (error) {
            console.error('Error deleting base:', error);
        }
    };

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                Base List
            </Typography>
            <List>
                {bases.map((base) => (
                    <ListItem
                        key={base.base_id}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteBase(base.base_id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={base.base_name} secondary={`ID: ${base.base_id}`} />
                    </ListItem>
                ))}
            </List>
            <Box display="flex" alignItems="center" gap={2} sx={{ mb: 2 }}>
                <TextField
                    variant="outlined"
                    label="Base ID"
                    value={newBaseId}
                    sx={{ padding: '0px' }}
                    onChange={(e) => setNewBaseName(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleAddBase}>
                    Add Base
                </Button>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                    Close
                </Button>
            </Box>
        </Box>
    );
};

export default BaseList;