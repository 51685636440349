import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, CircularProgress, Backdrop } from '@mui/material';
import AuthService from "../services/AuthService";
import userService from '../services/UserService';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'libphonenumber-js'; // Import the validation function

const UserForm = ({ onUserAddOrUpdate, onCancel, profile, operType, user }) => {
    const emptyUser = {
        username: '',
        email: '',
        phoneNumber: '', // Add phoneNumber to state
        profile: profile
    };

    const [formData, setFormData] = useState((operType === 'add') ? emptyUser : {
        username: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber, // Add phoneNumber to state
        profile: user.profile
    });
    const { t } = useTranslation();
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const token = AuthService.getAccessToken();
    if (!token) {
        console.error('No access token available');
        return;
    }

    const validateUsername = (username) => {
        if (/[A-Z]/.test(username)) {
            setUsernameError('Username must be in lowercase.');
            return false;
        } else {
            setUsernameError('');
            return true;
        }
    };

    const validatePhoneNumber = (phoneNumber) => {
        if (!isValidPhoneNumber(phoneNumber)) {
            setPhoneError('Invalid phone number format.');
            return false;
        } else {
            setPhoneError('');
            return true;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name === 'username') {
            validateUsername(value);
        } else if (name === 'phoneNumber') {
            // validatePhoneNumber(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateUsername(formData.username)) {
            return;
        }
        try {
            setLoading(true);
            let response;
            if (operType == 'add') {
                response = await userService.addUser(formData);
            } else {
                response = await userService.updateUser(formData);
            }
            setLoading(false);
            console.log(response);
            if (response.status === 200 || response.status === 201) {
                setResponseMessage('User created successfully!');
                setErrorMessage('');
                onUserAddOrUpdate();
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage('Failed to create user. ' + error.message);
            setResponseMessage('');
        }
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 3, mb: 6, p: 4, boxShadow: 2, borderRadius: 2, bgcolor: 'background.paper' }}>

            <Box component="form" onSubmit={handleSubmit} sx={{ textAlign: 'center' }}>

                {operType === 'edit' && (
                    <TextField
                        fullWidth
                        label={t('users.userName')}
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        margin="normal"
                        error={Boolean(usernameError)}
                        helperText={usernameError}
                        slotProps={{
                            input: { readOnly: true }
                        }}
                        sx={{
                            backgroundColor: 'action.disabledBackground', // A subtle background to indicate readonly
                            borderRadius: 1,
                            '& .MuiInputBase-input': {
                                fontSize: '1rem',
                                color: 'text.disabled', // Lighter color for readonly text
                                cursor: 'not-allowed', // Not-allowed cursor to indicate non-editability
                            },
                        }}
                    />
                )}
                {operType === 'add' && (
                    <TextField
                        fullWidth
                        required
                        label={t('users.userName')}
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        margin="normal"
                        error={Boolean(usernameError)}
                        helperText={usernameError}
                        sx={{
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            '& .MuiInputBase-input': { fontSize: '1rem' },
                        }}
                    />
                )}
                <TextField
                    fullWidth
                    required
                    type="email"
                    label={t('users.email')}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                        backgroundColor: 'background.default',
                        borderRadius: 1,
                        '& .MuiInputBase-input': { fontSize: '1rem' },
                    }}
                />

                <TextField
                    fullWidth
                    // required
                    label={t('users.phoneNumber')}
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    margin="normal"
                    error={Boolean(phoneError)}
                    helperText={phoneError}
                    sx={{
                        backgroundColor: 'background.default',
                        borderRadius: 1,
                        '& .MuiInputBase-input': { fontSize: '1rem' },
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onCancel();
                        }}
                        sx={{
                            mt: 3,
                            py: 1.2,
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            width: '150px',
                            borderRadius: 2,
                        }}

                    >
                        {t('users.cancel')}
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 3,
                            py: 1.2,
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            width: '150px',
                            borderRadius: 2,
                        }}
                        disabled={Boolean(usernameError) || Boolean(phoneError)}
                    >
                        {t('users.submit')}
                    </Button>
                </Box>

                {responseMessage && (
                    <Typography color="success.main" sx={{ mt: 2, fontSize: '1rem', fontWeight: '500' }}>
                        {responseMessage}
                    </Typography>
                )}
                {errorMessage && (
                    <Typography color="error.main" sx={{ mt: 2, fontSize: '1rem', fontWeight: '500' }}>
                        {errorMessage}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default UserForm;
