import React, { useState, useCallback } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Typography, Paper, LinearProgress, Snackbar, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import fileService from '../services/FileService';
import { useTranslation } from 'react-i18next';

const DropzoneContainer = styled(Paper)(({ theme, isDragActive }) => ({
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${theme.palette.grey[400]}`,
    borderRadius: isDragActive ? theme.palette.action.hover : theme.palette.background.paper,
    backgroundColor: isDragActive ? theme.palette.action.hover : theme.palette.background.paper,
    cursor: 'pointer',
    overflowY: 'auto',
    transition: 'background-color 0.3s ease',
}));

function MultipleFileUpload({ onUploadComplete, baseId }) {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [success, setSuccess] = useState(true);
    const { t } = useTranslation();

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
        setProgress({});
    }, []);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
    });

    const handleUpload = async () => {
        if (!files.length) return;

        setUploading(true);
        setProgress({});
        setSuccess(true);

        const token = AuthService.getAccessToken();
        if (!token) {
            setUploading(false);
            setOpenSnackbar(true);
            setSuccess(false);
            return;
        }

        try {
            const fileData = files.map((file) => ({
                fileName: file.name,
                fileType: file.type,
            }));

            const data = await fileService.generatePresign(baseId,fileData);

            const uploadPromises = files.map((file, index) => {
                const url = data[index];

                return axios.put(url, file, {
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: (progressEvent) => {
                        const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress((prevProgress) => ({
                            ...prevProgress,
                            [file.name]: progressPercentage,
                        }));
                    },
                });
            });

            await Promise.all(uploadPromises);

            setUploading(false);
            setSuccess(true);
            setOpenSnackbar(true);
            onUploadComplete();
            setFiles([]);
            setProgress({});
            setOpenDialog(false); // Close dialog after successful upload

        } catch (error) {
            console.error('Error uploading files:', error);
            setUploading(false);
            setSuccess(false);
            setOpenSnackbar(true);
        }
    };

    return (
        <Box sx={{ display: 'sm', flexDirection: 'column', alignItems: 'center' }}>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setOpenDialog(true)}
                sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: 2,
                    padding: '6px 16px',
                    '&:hover': { backgroundColor: 'primary.dark' },
                }}
            >
                {t('files.upload')}
            </Button>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm" sx={{
                "& .MuiDialog-paper": {
                    width: 500,
                    // height: 500, // Keeps the dialog box square
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden', // Prevents scrollbars
                }
            }}>
                <DialogTitle>{t('files.uploadFile')}</DialogTitle>
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    overflow: 'hidden', // Prevents scrollbars in content
                    width: '80%' // Ensures content fits within the dialog
                }}>
                    <DropzoneContainer {...getRootProps({ isDragActive })}>
                        <input {...getInputProps()} />
                        <CloudUploadIcon sx={{ fontSize: 50, color: 'grey.500', mb: 1 }} />
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'text.secondary' }}>
                            {isDragActive ? t('files.dropHere') : t('files.dragDrop')}
                        </Typography>
                    </DropzoneContainer>
                    {files.map((file) => (
                        <Box key={file.name} sx={{ mt: 2, width: '100%', maxWidth: 300, textAlign: 'center' }}>
                            <Typography variant="body2" sx={{ mb: 1 }}>{file.name}</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progress[file.name] || 0}
                                sx={{
                                    height: 6,       // Adjust height as needed
                                    borderRadius: 1,
                                    width: '100%'    // Fixed width for consistent sizing
                                }}
                            />
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setFiles([]); // Clear files when cancel is pressed
                        setOpenDialog(false);
                    }}>
                        {t('files.cancel')}
                    </Button>

                    <Button
                        onClick={handleUpload}
                        variant="contained"
                        disabled={!files.length || uploading}  // Disable button when no files selected or uploading
                    >
                        {uploading ? t('files.uploading') : t('files.upload')}
                    </Button>
                </DialogActions>
            </Dialog>


        </Box>
    );
}

export default MultipleFileUpload;
