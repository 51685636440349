// AuthService.js
import {CognitoAuth} from 'amazon-cognito-auth-js';
import cognitoConfig from './cognitoConfig';
import {jwtDecode} from 'jwt-decode';


class CustomCognitoAuth extends CognitoAuth {
    getFQDNSignIn() {
        // Custom implementation
        console.log("Overridden getFQDNSignIn method");
        const urlOrg = super.getFQDNSignIn(); // Call original if needed
        return `${urlOrg}&lang=ja`;
    }

   
    cacheTokensScopes() {
        console.log("Caching tokens and scopes");
        const keyPrefix = `CognitoIdentityServiceProvider.${this.getClientId()}`;
        // const tokenUserName = this.signInUserSession.getAccessToken().getUsername();
        const tokenUserName = this.getCurrentUser();
        this.username = tokenUserName;
        const idTokenKey = `${keyPrefix}.${tokenUserName}.idToken`;
        const accessTokenKey = `${keyPrefix}.${tokenUserName}.accessToken`;
        const refreshTokenKey = `${keyPrefix}.${tokenUserName}.refreshToken`;
        const lastUserKey = `${keyPrefix}.LastAuthUser`;
        const scopeKey = `${keyPrefix}.${tokenUserName}.tokenScopesString`;
        const scopesArray = this.signInUserSession.getTokenScopes().getScopes();
        const scopesString = scopesArray.join(' ');
        this.storage.setItem(idTokenKey, this.signInUserSession.getIdToken().getJwtToken());
        this.storage.setItem(accessTokenKey, this.signInUserSession.getAccessToken().getJwtToken());
        this.storage.setItem(refreshTokenKey, this.signInUserSession.getRefreshToken().getToken());
        this.storage.setItem(lastUserKey, tokenUserName);
        this.storage.setItem(scopeKey, scopesString);
      }
    
}

class AuthService {
    constructor() {
        this.auth = new CustomCognitoAuth(cognitoConfig);
        this.auth.useCodeGrantFlow();
        this.loginAs = null;
        this.auth.userhandler = {
            onSuccess: this.onSuccess.bind(this),
            onFailure: this.onFailure.bind(this),
        };
    }

    onSuccess(session) {
        console.log("Logged in successfully", session);
        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        sessionStorage.setItem('id_token', idToken);
        sessionStorage.setItem('access_token', accessToken);
        window.location.href = '/';
    }

    onFailure(err) {
        console.error("Error during authentication", err);
    }

    login() {
        this.auth.getSession();
    }

    getGroup() {
        const jwtToken = this.getAccessToken();
        // console.log("token ");
        // console.log(jwtToken);
        // Decode the ID token
        if (jwtToken) {
            const decodedToken = jwtDecode(jwtToken);
            // Extract the 'cognito:groups' claim
            return decodedToken['cognito:groups'];
        }
    }

    logout() {
        const logoutUrl = `https://${cognitoConfig.AppWebDomain}/logout?client_id=${cognitoConfig.ClientId}&logout_uri=${encodeURIComponent(cognitoConfig.RedirectUriSignOut)}`;
        this.auth.signInUserSession = null;
        this.auth.clearCachedTokensScopes();
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('access_token');
        console.log('Redirecting to logout');
        window.location.href = logoutUrl;
    }

    getCurrentUser() {
        const jwtToken = this.getAccessToken();
        if (jwtToken) {
            const decodedToken = jwtDecode(jwtToken);
            return decodedToken['username'];
        }else{
            return 'N/A';
        }
    }

    getLoginAs() {
        return this.loginAs;
    }

    setLoginAs(loginAs) {
        this.loginAs = loginAs;
    }

    parseCognitoResponse(url) {
        console.log(`Parsing Cognito response ${url}`);
        this.auth.parseCognitoWebResponse(url);
    }

    isAuthenticated() {
        const idToken = sessionStorage.getItem('id_token');
        return idToken !== null;
    }

    getIdToken() {
        return sessionStorage.getItem('id_token');
    }

    getAccessToken() {
        return sessionStorage.getItem('access_token');
    }
}

const authService = new AuthService();
export default authService;