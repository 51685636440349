import AuthService from './AuthService';
import axios from 'axios';

class UserService{
    constructor() {
        this.apiBaseUrl=process.env.REACT_APP_API_URL;
    }

    async getUsers(profile){
        const token = AuthService.getAccessToken();
        const loginAs = AuthService.getLoginAs();
        const loginAsUri = loginAs ? `&loginAs=${loginAs}` : '';
        const response = await fetch(`${this.apiBaseUrl}/users?profile=${profile}${loginAsUri}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        console.log('user service called');
        const data = await response.json();
        return data;
    }

    async enableUser(userName, enable){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/users/${userName}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ enable: enable }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);

        }
    }

    async updateUser(user){
        const token = AuthService.getAccessToken();
        console.log(`updating user ${user}`);
        const response = await fetch(`${this.apiBaseUrl}/users/${user.username}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(user),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response;
    }

    async deleteUser(userName){
        const token = AuthService.getAccessToken();
        const response = await fetch(`${this.apiBaseUrl}/users/${userName}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
        if (!response.ok) {
            const errorDetails = await response.text();
            throw new Error(`HTTP error! Status: ${errorDetails}`);
        }
        return response;
    }

    async addUser(user){
        const token = AuthService.getAccessToken();
        const response = await axios.post(`${this.apiBaseUrl}/users`, user, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    }
}
const userService = new UserService();
export default userService;